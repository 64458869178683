import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import GameManager from "../components/GameManager"
import AuthContext from "../firebase/auth/AuthContext"

export default function Facilitator() {
    const authContext = useContext(AuthContext)
    const navigator = useNavigate()
    useEffect(() => {
        if (authContext.access == null) return
        if (!(authContext.access === "facilitator" || authContext.access === "admin")) {
            // SnackbarUtils.warning("You're not a facilitator !")
            // if (authContext.access === "admin")
            //     navigator("/admin")
            // else 
            navigator("/login")
        }
    }, [authContext.user])

    return <>
        <GameManager />
    </>
}
