import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tab, TablePagination, Tabs, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { getIdToken } from "firebase/auth"
import { collection, endBefore, getCountFromServer, getDocs, limit, limitToLast, orderBy, query, startAfter } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../firebase/auth/AuthContext"
import firestore from "../../firebase/firestore"
import bring from "../bring"
import LoaderUtils from "../Loader/LoaderUtils"
import SearchSpace from "../SearchSpace"
import SnackbarUtils from "../SnackbarUtils"
import StyledButton from "../StyledButton"
import FacilitatorEditor from "./FacilitatorEditor"
import LicenceEditor from "./LicenceEditor"


const FacilitatorManager = () => {
    const matches = useMediaQuery("(min-width:756px)")
    const theme = useTheme()
    const navigator = useNavigate()
    const authContext = useContext(AuthContext)
    const [licenceLists, setlicenceLists] = useState([])
    const [facilitatorLists, setFacilitatorLists] = useState([])
    const [dialog, setDialog] = useState({
        open: false,
        type: null,
        index: -1
    })
    const [searchDialog, setSearchDialog] = useState({
        open: false,
        type: "facilitator",
    })
    const [page, setPage] = useState(0)
    const [hoverIndex, setHoverIndex] = useState(-1)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const handleChangePage = (event, newPage) => {
        //console.log("page changed", newPage)
        const first = query(
            collection(firestore, tab === "facilitator" ? "facilitators" : "licences"),
            orderBy("created"),
            ((newPage > page) ? 
                startAfter(tab === "facilitator" ? 
                    facilitatorLists[facilitatorLists.length - 1].created
                    :
                    licenceLists[licenceLists.length - 1].created)
                :
                endBefore(tab === "facilitator" ? 
                    facilitatorLists[0].created
                    :
                    licenceLists[0].created)
            ),
            ((newPage > page) ?
                limit(rowsPerPage)
                :
                limitToLast(rowsPerPage)
            )
        )
        //console.log(first)
        let arr = []
        getDocs(first)
            .then(res => {
                LoaderUtils.close()
                res.forEach(result => {
                    arr.push({
                        ...result.data(),
                        id: result.id,
                    })
                })
                tab === "facilitator" ? setFacilitatorLists(arr) : setlicenceLists(arr)
                // //console.log(res.docs)
            })
            .catch(e => {
                LoaderUtils.close()
                //console.error(e)
            })

        setPage(newPage)
    }
    
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }
    const [openEditor, setOpenEditor] = useState({open : false, tab: null, details: null})
    const [reload, setReload] = useState(0)
    const [tab, setTab] = React.useState("licence")
    // Go to firsts page on tab change
    useEffect(() => setPage(0), [tab])

    // Get count of documents
    const [facilitatorCount, setFacilitatorCount] = useState(0)
    const [licenceCount, setLicenceCount] = useState(0)
    useEffect(() => {
        (async () => {
            const fac = await getCountFromServer(collection(firestore, "facilitators"))
            setFacilitatorCount(fac.data().count)
            const lic = await getCountFromServer(collection(firestore, "licences"))
            setLicenceCount(lic.data().count)
        })()
    }, [reload])
    // End for count of documents

    // First time fetch data
    useEffect(() => {
        //console.log(page, rowsPerPage)
        LoaderUtils.open()
        if (tab === "facilitator") {
            const first = query(
                collection(firestore, "facilitators"),
                orderBy("created"),
                limit(rowsPerPage)
            )
            //console.log(first)
            let arr = []
            getDocs(first).then(res => {
                LoaderUtils.close()
                res.forEach(result => {
                    arr.push({
                        ...result.data(),
                        id: result.id
                    })
                })
                setFacilitatorLists(arr)
                // //console.log(res.docs)
            }).catch(e => {
                LoaderUtils.close()
                //console.error(e)
            })
        }
        else if (tab === "licence") {
            // pagination still remaining
            const first = query(
                collection(firestore, "licences"),
                orderBy("created", "desc"),
                limit(rowsPerPage)
            )
            //console.log(first)

            let arr = []
            getDocs(first)
            .then(res => {
                LoaderUtils.close()
                res.forEach(result => {
                    arr.push({
                        ...result.data(),
                        gameIds: null,
                        id: result.id,
                    })
                })
                setlicenceLists(arr)
            })
            .catch(e => {
                LoaderUtils.close()
                //console.error(e)
            })
        }
        
    }, [reload, tab, rowsPerPage])
    
    
    const deletelicence = async () => {
        //console.log(dialog.type === "licence" ? licenceLists[dialog.index] : facilitatorLists[dialog.index])
        LoaderUtils.halt()
        setDialog({
            ...dialog, open: false
        })
        // //console.log(authContext)
        try {
            const jwt = await getIdToken(authContext.user, true)
            const data = dialog.type === "licence" ? {
                id: licenceLists[dialog.index].id,
                token: jwt,
            } : {
                email: facilitatorLists[dialog.index].email,
                uid: facilitatorLists[dialog.index].id,
                token: jwt,
            }
            const response = await bring({
                path: dialog.type === "licence" ? "/deleteLicence" : "/deleteFacilitator",
                options: {
                    method: "POST",
                    credentials: "include",
                    cors: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                },
            })
            
            LoaderUtils.unhalt()
            if (response.status === "success") {
                SnackbarUtils.success("Deletion Successful")
                if (dialog.type === "licence") {
                    const arr = [...licenceLists]
                    arr.splice(dialog.index, 1)
                    setlicenceLists(arr)
                    setLicenceCount(licenceCount - 1)
                } else {
                    const arr = [...facilitatorLists]
                    arr.splice(dialog.index, 1)
                    setFacilitatorLists(arr)
                    setFacilitatorCount(facilitatorCount - 1)
                }
                setDialog({
                    open: false,
                    type: null,
                    index: -1,
                })
            } else {
                //console.error(response)
                SnackbarUtils.error("Error : " + response.message)
            }
            
        } catch (error) {
            SnackbarUtils.error(error.message || error)
        } finally {
            LoaderUtils.unhalt()
        }
        setDialog({
            open: false,
            type: null,
            index: -1
        })
    }
    
    const openlicenceCreator = (isNew = true, which, id) => {
        //console.log(isNew, which, id)
        // check if new or edit and open editor
        // if edit, fetch details from server
        // if new, open editor with empty details
        // ################################################################ Start from here tomorrow @ 10:30 AM ################################################################
        if (isNew) setOpenEditor({open: true, tab: which, details: null})
        else setOpenEditor({open: true, tab: which, details: (id > -1) ? (
            tab === "licence" ? licenceLists[id] : facilitatorLists[id]
            ) : null})
    }
    return (
        <>
            <h3 style={{ paddingLeft: 24, display: "inline-block", fontSize: "1.5em" }}>Admin Panel</h3>
            <StyledButton variant="contained" color="button" onClick={() => openlicenceCreator(true, "licence", -1)} sx={{ float: "right", margin: "20px" }}>
                <b>licence</b>
                <span className="material-icons" style={{ marginLeft: 6 }}>
                    add
                </span>
            </StyledButton>
            <StyledButton variant="contained" color="button" onClick={() => openlicenceCreator(true, "facilitator", -1)} sx={{ float: "right", margin: "20px 0" }}>
                <b>facilitator</b>
                <span className="material-icons" style={{ marginLeft: 6 }}>
                    add
                </span>
            </StyledButton>
            <StyledButton variant="contained" color="secondary" onClick={() => navigator("/facilitator")} sx={{ float: "right", margin: "20px" }}>
                <b>Go to Facilitator page</b>
            </StyledButton>
            <br></br>
            <br></br>
            <div
                style={{
                    position: "relative",
                    backgroundColor: theme.palette.white.main,
                    display: "flex",
                    minWidth: 300,
                    maxWidth: matches ? "calc(100% - 80px)" : "100%",
                    margin: "0 auto",
                    width: "100%",
                    boxShadow: theme.shadows[5],
                }}
            >
                <Tabs
                    // style={{ minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: "0 auto", width: "100%" }}
                    value={tab}
                    onChange={(event, newValue) => setTab(newValue)}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="licences and facilitators Tabs"
                >
                    <Tab value="licence" label="licences" />
                    <Tab value="facilitator" label="facilitators" />
                </Tabs>
                <Button
                    variant="text"
                    sx={{ position: "absolute", height: "inherit", top: 4, right: 0, float: "right" }}
                    onClick={() => {
                        setSearchDialog({ open: true, type: tab })
                    }}
                >
                    <span className="material-icons" style={{ float: "right", margin: "auto", display: "inline-block", cursor: "pointer" }}>
                        search
                    </span>
                </Button>
            </div>
            <TableContainer sx={{ minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: "auto" }} component={Paper}>
                <div style={{ maxHeight: "65vh", overflowY: "scroll", borderBottom: "1px solid lightgrey" }}>
                    <Table stickyHeader aria-label="Active licences table">
                        <TableHead>
                            <TableRow>
                                {tab === "licence" ? (
                                    <>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Licences Name
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Facilitator Name
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            # of Licences
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Purchase Date
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Delete
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Facilitator Name
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="left">
                                            Email
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Phone
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: "bold" }} align="center">
                                            Delete / Edit
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableHead>
                        {tab === "licence" ? (
                            <TableBody>
                                {licenceLists.length > 0 ? (
                                    licenceLists.map((value, index, array) => (
                                        <TableRow key={index}>
                                            <TableCell align="center">{value.licenceName}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => {
                                                        localStorage.setItem("facilitatorName", value.facilitatorName)
                                                        window.open("/facilitator#UID" + value.facilitator, "_blank")
                                                    }}
                                                    style={{ textTransform: "none" }}
                                                >
                                                    {value.facilitatorName}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">{value.amount}</TableCell>
                                            <TableCell align="center">{new Date(value.created.seconds * 1000).toLocaleDateString()}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Delete Licence" arrow>
                                                    <Button
                                                        variant="text"
                                                        color="error"
                                                        onClick={() => {
                                                            setDialog({ open: true, type: "licence", index })
                                                        }}
                                                    >
                                                        <span className="material-icons">delete</span>
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                            {/* <TableCell align="center">
                                                <Tooltip title={value.buyers > 0 ? (value.active ? "Hide licence" : "Show licence") : "Delete licence"} arrow>
                                                    <StyledButton variant="text" color={value.buyers > 0 ? (value.active ? "success" : "warning") : "error"} onClick={() => deletelicence(index)}>
                                                        <span className="material-icons">{value.buyers > 0 ? (value.active ? "visibility_off" : "visibility") : "delete"}</span>
                                                    </StyledButton>
                                                </Tooltip>
                                            </TableCell> */}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <h3>No licences created .</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        ) : (
                            <TableBody>
                                {facilitatorLists.length > 0 ? (
                                    facilitatorLists.map((value, index, array) => (
                                        <TableRow key={index} onMouseEnter={() => setHoverIndex(index)} onMouseLeave={() => setHoverIndex(-1)}>
                                            <TableCell align="center">
                                                {/* <StyledButton variant="text" color="primary" LinkComponent={Link} to={`/facilitator#UID${value.id}`} style={{ textTransform: "none" }}>
                                                    {value.name}
                                                </StyledButton> 
                                                // clicking on nlink willl add value.name to localStroage and then open a new tab with the facilitator page
                                                */}
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    onClick={() => {
                                                        localStorage.setItem("facilitatorName", value.name)
                                                        window.open("/facilitator#UID" + value.id, "_blank")
                                                    }}
                                                    style={{ textTransform: "none" }}
                                                >
                                                    {value.name}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="left">{value.email}</TableCell>
                                            <TableCell align="center">{value.phone || "-"}</TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Delete Licence" arrow>
                                                    <Button
                                                        variant="text"
                                                        color="error"
                                                        onClick={() => {
                                                            setDialog({ open: true, type: "facilitator", index })
                                                        }}
                                                    >
                                                        <span className="material-icons">delete</span>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="View / Edit facilitator" arrow>
                                                    <Button
                                                        variant="text"
                                                        color="secondary"
                                                        onClick={() => {
                                                            openlicenceCreator(false, "facilitator", index)
                                                        }}
                                                        style={{
                                                            position: "relative",
                                                            opacity: hoverIndex === index ? 1 : 0,
                                                        }}
                                                    >
                                                        <span className="material-icons">edit</span>
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <h3>No facilitators created .</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </div>
                <TablePagination
                    component="div"
                    count={tab === "licence" ? licenceCount : facilitatorCount}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            {/* Confirmation Dialog */}
            <Dialog open={dialog.open} onClose={() => setDialog({ open: false, index: -1 })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure to proceed with deleting this{" "}
                        <b>
                            {dialog.type + " - " + (dialog.type === "licence" ? licenceLists[dialog.index]?.licenceName || "" : facilitatorLists[dialog.index]?.name || "")} ?<br></br>
                        </b>
                        <br></br>
                        <Alert severity="error">This action cannot be undone.</Alert>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="outlined"
                        onClick={() => {
                            setDialog({ open: false, index: -1 })
                        }}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="error" onClick={deletelicence} autoFocus>
                        Delete
                    </StyledButton>
                </DialogActions>
            </Dialog>

            {openEditor.open && openEditor.tab === "facilitator" ? (
                <FacilitatorEditor details={openEditor} setOpenEditor={setOpenEditor} updateState={setReload} setFacilitatorLists={setFacilitatorLists} />
            ) : null}
            {openEditor.open && openEditor.tab === "licence" ? (
                <LicenceEditor details={openEditor} facilitatorLists={facilitatorLists} setOpenEditor={setOpenEditor} updateState={setReload} setlicenceLists={setlicenceLists} />
            ) : null}
            {searchDialog.open ? <SearchSpace searchDialog={searchDialog} setSearchDialog={setSearchDialog} /> : null}
        </>
    )
}

export default FacilitatorManager
