import { useTheme } from "@emotion/react"
import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TablePagination, TextField, useMediaQuery } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { ref, update } from "firebase/database"
import { Timestamp, addDoc, collection, doc, getDoc, onSnapshot, query, runTransaction, updateDoc, where, writeBatch } from "firebase/firestore"
import React, { useContext, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import AuthContext from "../../firebase/auth/AuthContext"
import database from "../../firebase/database"
import firestore from "../../firebase/firestore"
import LoaderUtils from "../Loader/LoaderUtils"
import SnackbarUtils from "../SnackbarUtils"
import StyledButton from "../StyledButton"
import webShareAPI from "../webShareAPI"

const GameManager = () => {
    const [adminMode, setAdmiinMode] = useState(window.location.hash.startsWith("#UID") ? window.location.hash.substring(4) : null)
    const matches = useMediaQuery("(min-width:756px)")
    const theme = useTheme()
    const authProvider = useContext(AuthContext)
    const navigator = useNavigate()
    const sessionInputRef = useRef(null)
    const [gameList, setGameList] = useState([])
    const [sessionList, setSessionList] = useState([])
    const [dialog, setDialog] = useState({
        open: false,
        index: -1,
        session: ""
    })
    const [prompt, setPrompt] = useState({
        open: false,
        index: -1
    })
    // const [search, setSearch] = useState(null)
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)
    const [count, setCount] = useState(0)
    const [gameIds, setGameIds] = useState({})


    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleGameData = (data, id) => {
        let sessions = new Set()
        // //console.table(data.gameIds)
        setGameIds(d => {
            d[id] = data.gameIds
            return d
        })
        setGameList(l => {
            for (let i = 0; i < data.gameIds.length; i++) {
                const gameId = data.gameIds[i]
                // console.log(gameId)
                if (gameId.session && ((Date.now() - 1000 * (gameId?.created?.seconds || 0)) < 28800000)) sessions.add(gameId.session)
                // l.push({
                //     licenceID: id,
                //     index: i,
                //     id: gameId.licenceName,
                //     name: gameId.gameName,
                //     session: gameId.session,
                //     date: data.created,
                //     localID: gameId.localID,
                //     status: gameId.status,
                // })
                // check if game is already in list then merge
                let found = false
                for (let j = 0; j < l.length; j++) {
                    if (l[j].id === gameId.licenceName) {
                        l[j].name = gameId.gameName
                        l[j].session = gameId.session
                        l[j].status = gameId.status
                        l[j].localID = gameId.localID
                        found = true
                        break
                    }
                }
                if (!found) {
                    l.push({
                        licenceID: id,
                        index: i,
                        id: gameId.licenceName,
                        name: gameId.gameName,
                        session: gameId.session,
                        date: data.created,
                        localID: gameId.localID,
                        status: gameId.status,
                    })
                }
            }
            // //console.table(l)
            return l
        })
        setSessionList(s => [...new Set([...s, ...sessions])])
        setCount(c => c + data.amount)
    }

    const handleModifiedGameData = (data, id) => {
        let sessions = new Set(sessionList)
        setGameList(l => {
            for (let i = 0; i < data.gameIds.length; i++) {
                const gameId = data.gameIds[i]
                if (gameId.session && (Date.now() - 1000 * (gameId?.created?.seconds || 0) < 28800000)) sessions.add(gameId.session)
                if (l[i].id === gameId.licenceName) {
                    l[i].name = gameId.gameName
                    l[i].session = gameId.session
                    l[i].status = gameId.status
                    l[i].localID = gameId.localID
                }
            }
            return l
        })
        setSessionList([...sessions])
    }

    // Set up periodic check over deletion fron game session timeout
    useEffect(() => {
        const forDeletionRef = query(collection(firestore, "forDeletion"), where("created", "<", Timestamp.fromDate(new Date(Date.now() - 1000 * 60 * 60 * 8))))
        onSnapshot(forDeletionRef, async snapshot => {
            const batch = writeBatch(firestore)
            let arr = []
            snapshot.forEach(docu => arr.push(docu))
            await Promise.all(arr.map(async docu => {
                // data to be deleted
                const data = docu.data()
                let tempGameIDList = []
                if (!gameIds[data.licenceID]) {
                    tempGameIDList = (await getDoc(doc(firestore, "licences", data.licenceID))).data()
                    if (tempGameIDList) tempGameIDList = tempGameIDList.gameIds || undefined
                    if (tempGameIDList)
                        setGameIds(d => {
                            d[docu.id] = tempGameIDList
                            return d
                        })
                }
                else tempGameIDList = gameIds[data.licenceID]

                for (let i = 0; tempGameIDList && i < tempGameIDList.length; i++) {
                    if (tempGameIDList[i].licenceName === data.gameID) {
                        tempGameIDList[i] = {
                            licenceName: data.gameID,
                            gameName: null,
                            session: null,
                            status: 0
                        }
                        batch.update(doc(firestore, "licences", data.licenceID), {
                            gameIds: tempGameIDList
                        })
                        break
                    }
                }
                batch.delete(doc(firestore, "forDeletion", docu.id))
                batch.delete(doc(collection(firestore, "games"), data.gameID))
            }))

            await batch.commit()
            // //console.log(await batch.commit())
        })

        // add hash change listner
        window.addEventListener("hashchange", () => {
            setAdmiinMode(window.location.hash.startsWith("#UID") ? window.location.hash.substring(4) : null)
        })
        return
    }, [])

    // Check for lost timeout games
    useEffect(() => {
        if (!authProvider.user) return
        console.log("timeout check")
        // from collection timeoutCheck , query documents by facilitator and created < 45 mins in realtime
        const timeoutCheckRef = query(collection(firestore, "timeoutCheck"), where("created", "<", Timestamp.fromDate(new Date(Date.now() - 1000 * 60 * 45))))
        onSnapshot(timeoutCheckRef, async snapshot => {
            // get data from snapshot
            const data = snapshot.docs.map(docu => {
                const data = docu.data()
                data.key = docu.id
                return data
            })
            // update values in licence collection in gameIds array for each game status to 4 and delete from timeoutCheck collection
            const batch = writeBatch(firestore)
            for (let i = 0; i < data.length; i++) {
                // update gameIds array in licence collection
                const gameIds = (await getDoc(doc(firestore, "licences", data[i].licence))).data().gameIds
                console.log(gameIds)
                for (let j = 0; j < gameIds.length; j++) {
                    if (gameIds[j].licenceName === data[i].gameId) {
                        gameIds[j].status = 4
                        break
                    }
                }
                batch.update(doc(firestore, "licences", data[i].licence), {
                    gameIds: gameIds
                })
                // delete from timeoutCheck collection
                batch.delete(doc(firestore, "timeoutCheck", data[i].key))
            }
            await batch.commit()
        })
    }, [authProvider.user])

    // Get game list
    useEffect(() => {
        setPage(0)
        //console.log(`fetching game lists`);
        LoaderUtils.open();
        (async () => {
            try {
                const q = query(collection(firestore, "licences"), where("facilitator", "==", adminMode || authProvider.user.uid))
                onSnapshot(q, snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "added") {
                            // //console.log("New Game: ", change.doc.data(), change.doc.id);
                            handleGameData(change.doc.data(), change.doc.id)
                        }
                        if (change.type === "modified") {
                            // //console.log("Modified Game: ", change.doc.data())
                            handleModifiedGameData(change.doc.data(), change.doc.id)
                        }
                        if (change.type === "removed") {
                            // This case wont ever come
                            // //console.log("Removed Game: ", change.doc.data())
                            // handleDeletedGameData(change.doc.data(), change.doc.id)
                        }
                    })
                })
            } catch (error) {
                //console.log(error)
            } finally {
                LoaderUtils.close()
            }
        })()
    }, [authProvider.user, adminMode])

    const deleteactive = (index) => {
        const value = gameList[index]
        //console.log(index, "deleted")
    }

    const assignSession = (index, session) => {
        return new Promise((resolve, reject) => {
            // //console.log(gameList[index], session)
            // Regex to check if string contains illegal characters and spaces
            if (session.match(/[^a-zA-Z0-9]/g) || session.match(/\s/g) || session.length === 0) {
                reject({
                    message: "Session name can only contain alphanumeric characters"
                })
            } 
            runTransaction(firestore, async transaction => {
                const licenceRef = doc(firestore, "licences", gameList[index].licenceID)
                const licenceDoc = await transaction.get(licenceRef)
                if (!licenceDoc.exists()) {
                    reject({
                        message: "Licence does not exist"
                    })
                }
                const newGameIds = [...licenceDoc.data().gameIds]
                newGameIds[gameList[index].index].session = session
                newGameIds[gameList[index].index].created = Timestamp.now()
                transaction.update(licenceRef, {
                    gameIds: newGameIds
                })

                // Create new document in games collection in firestore
                const gameRef = doc(firestore, "games", gameList[index].id)
                transaction.set(gameRef, {
                    facilitator: adminMode || authProvider.user.uid,
                    licence: gameList[index].licenceID,
                    session: session,
                    gameName: gameList[index].name,
                    status: gameList[index].status,
                    created: Timestamp.now(),
                    gameData: {},
                })

                await addDoc(collection(firestore, "forDeletion"), {
                    created: Timestamp.now(),
                    gameID: gameList[index].id,
                    index: gameList[index].index,
                    licenceID: gameList[index].licenceID,
                })
            }).then(() => resolve()).catch(error => reject(error))
  
            // reject({message: "Not implemented"})
        })
    }
    const getStatusFromStatusIndex = (index) => {
        switch (index) {
            case 0:
                return "Unused"
            case 1:
                return "Ongoing"
            case 2:
                return "Completed"
            case 3:
                return "Surrended"
            case 4:
                return "Failed"
            default:
                return "Unknown"
        }
    }

    const submitSession = async () => {
        // //console.log(prompt)
        try {
            await assignSession(prompt.index, prompt.session)
            setPrompt({ open: false, index: -1, session: "" })
            
        } catch (error) {
            //console.error(error)
            SnackbarUtils.error(error.message)
        }
    }

    const resetLocalID = async (gameID) => {
        try {
            await updateDoc(doc(firestore, "games", gameID), {
                localID: null
            })
            await update(ref(database.db, `games/${gameID}`), {localID: null}, { merge: true })
 
            SnackbarUtils.success("Device ID reset")
        } catch (error) {
            //console.error(error)
            SnackbarUtils.error(error.message)
        }
    }

    return (
        <>
            <h3 style={{ paddingLeft: 24, display: "inline-block", fontSize: "1.5em" }}>{adminMode ? "Facilitator : " + window.localStorage.getItem("facilitatorName") : "Facilitator Panel"}</h3>
            {authProvider.access === "admin" ? (
                <StyledButton variant="contained" color="secondary" onClick={() => navigator("/admin")} sx={{ float: "right", margin: "20px" }}>
                    <b>Go to Admin page</b>
                </StyledButton>
            ) : null}
            <br></br>
            {/* <div style={{ display: "flex", minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: "0 auto", width: "100%" }}>
                <Autocomplete
                    // multiple
                    // open={autoCompleteOpen}
                    // onOpen={loadList}
                    // onClose={() => setAutoCompleteOpen(false)}
                    value={search}
                    size="small"
                    sx={{
                        width: 300,
                        maxWidth: matches ? "calc(100% - 80px)" : "100%",
                        float: "right",
                        margin: "auto 0 0 auto",
                        display: "inline-block",
                    }}
                    isOptionEqualToValue={(option, value) => option.session === value.session}
                    options={gameList}
                    getOptionLabel={option => option.session}
                    freeSolo
                    // onChange={(event, newInputValue) => setValues({ ...values, tags: newInputValue })}
                    renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option.session} {...getTagProps({ index })} />)}
                    renderInput={params => <TextField {...params} variant="outlined" label="Search" />}
                />
            </div> */}
            <TableContainer sx={{ minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: "auto", boxShadow: theme.shadows[5] }} component={Paper}>
                <div style={{ maxHeight: "70vh", overflowY: "scroll", borderBottom: "1px solid lightgrey" }}>
                    <Table stickyHeader aria-label="Active actives table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">
                                    Team Name
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">
                                    Game ID
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">
                                    Session
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">
                                    Date Created
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold" }} align="center">
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {gameList.length > 0 ? (
                                (() => {
                                    let rows = []
                                    // //console.table(gameList) // problem with this line
                                    for (let i = page * rowsPerPage; i < page * rowsPerPage + rowsPerPage; i++) {
                                        if (i >= gameList.length) break
                                        const value = gameList[i]
                                        rows.push(
                                            <TableRow
                                                key={i}
                                                // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                // onClick={() => setDialog({ open: true, index: i })}
                                            >
                                                <TableCell align="center" style={{ position: "relative" }}>
                                                    {value.name ? (
                                                        <>
                                                            <Link
                                                                to={`/report/game/${value.id}`}
                                                                // target="_blank"
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: theme.palette.secondary.main,
                                                                }}
                                                            >
                                                                {value.name}
                                                            </Link>
                                                            <Button
                                                                variant="text"
                                                                color="warning"
                                                                style={{
                                                                    position: "absolute",
                                                                    right: 0,
                                                                    margin: -7,
                                                                }}
                                                                disabled={value.status > 1}
                                                            >
                                                                <span
                                                                    className="material-icons"
                                                                    onClick={() => {
                                                                        if (value.status > 1) return
                                                                        resetLocalID(value.id)
                                                                    }}
                                                                >
                                                                    lock_reset
                                                                </span>
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        "Not Set"
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    onClick={() => {
                                                        if (value.status > 1) return
                                                        if (value.session) webShareAPI(window.location.origin + "/#" + value.id)
                                                        else SnackbarUtils.toast("Please assign a session name before sharing.")
                                                    }}
                                                >
                                                    <Button variant="text" color={value.session ? "primary" : "inherit"} disabled={value.status > 1}>
                                                        {value.id}
                                                        <span className="material-icons" style={{ fontSize: "inherit", marginLeft: 8, verticalAlign: "middle" }}>
                                                            content_copy
                                                        </span>
                                                    </Button>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {value.session ? (
                                                        <Link
                                                            to={"/report/session/" + encodeURI(value.session)}
                                                            // target="_blank"
                                                            style={{
                                                                textDecoration: "none",
                                                                color: theme.palette.secondary.main,
                                                            }}
                                                        >
                                                            {value.session}
                                                        </Link>
                                                    ) : (
                                                        <Button variant="text" color="warning" sx={{ textTransform: "none" }} onClick={() => setPrompt({ open: true, index: i, session: "" })}>
                                                            Assign
                                                            <span className="material-icons" style={{ fontSize: "inherit", marginLeft: 8, verticalAlign: "middle" }}>
                                                                add
                                                            </span>
                                                        </Button>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">{new Date(value.date.seconds * 1000).toLocaleDateString()}</TableCell>
                                                <TableCell align="center">{getStatusFromStatusIndex(value.status)}</TableCell>
                                            </TableRow>
                                        )
                                    }
                                    return rows
                                })()
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        <h3>No Licences available.</h3>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    component="div"
                    count={adminMode ? Math.ceil(count / 2) : count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            {/* Confirmation Dialog */}
            <Dialog open={dialog.open} onClose={() => setDialog({ open: false, index: -1 })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure to proceed with deleting this active {"#" + dialog.index} ?<br></br> This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton
                        variant="outlined"
                        onClick={() => {
                            setDialog({ open: false, index: -1 })
                        }}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton
                        variant="contained"
                        color="error"
                        onClick={async () => {
                            deleteactive(dialog.index)
                            setDialog({ open: false, index: -1 })
                        }}
                        autoFocus
                    >
                        Delete
                    </StyledButton>
                </DialogActions>
            </Dialog>
            <Dialog open={prompt.open} onClose={() => setPrompt({ open: false, index: -1 })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>{"Choose Session"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select or Create a Session Name to assign to the Game ID ?<br></br>{" "}
                        <small style={{ color: theme.palette.error.main }}>
                            <b>
                                <i>This action cannot be undone !</i>
                            </b>
                        </small>
                    </DialogContentText>
                    <Autocomplete
                        // multiple
                        // open={autoCompleteOpen}
                        // onOpen={loadList}
                        // onClose={() => setAutoCompleteOpen(false)}
                        value={prompt.session}
                        options={sessionList}
                        freeSolo
                        onChange={(event, newInputValue) => {
                            setPrompt({ ...prompt, session: newInputValue })
                        }}
                        renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)}
                        renderInput={params => (
                            <TextField
                                {...params}
                                inputRef={sessionInputRef}
                                variant="outlined"
                                label="Session"
                                onChange={event => {
                                    setPrompt({ ...prompt, session: event.target.value })
                                }}
                                placeholder="Session name"
                            />
                        )}
                    />
                    {/* <Alert severity="info" sx={{ marginTop: 2 }}>Press <b>Enter Key</b> after entering a new session name.</Alert> */}
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 3 }}>
                    <StyledButton
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                            setPrompt({ open: false, index: -1, session: "" })
                        }}
                        sx={{ mr: 2 }}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="secondary" onClick={submitSession} autoFocus>
                        Assign
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default GameManager
