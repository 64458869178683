import IconButton from "@mui/material/IconButton"
import { useTheme } from "@mui/material/styles"
import { onAuthStateChanged } from "firebase/auth"
import { SnackbarProvider } from "notistack"
import React, { useEffect, useRef, useState } from "react"
import { batch } from "react-redux"
import { Route, Routes } from "react-router-dom"
import Loader from "./components/Loader"
import LoaderUtils from "./components/Loader/LoaderUtils"
import Snack from "./components/Snack"
import NavBar from "./components/navbar"
import auth from "./firebase/auth"
import AuthContext from "./firebase/auth/AuthContext"
import logo from "./media/logoTransparent.png"
import Admin from "./pages/Admin"
import Facilitator from "./pages/Facilitator"
import Home from "./pages/Home"
import Join from "./pages/Join"
import { GameReport, SessionReport } from "./pages/Report"

function App() {
    const notistackRef = useRef()
    const theme = useTheme()
    const [user, setUser] = useState(null)
    const [access, setAccess] = useState(null)
    const [title, setTitle] = useState("The Quest for King Solomon's Mines")

    useEffect(() => {
        LoaderUtils.halt()
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                //console.log(user.email)
                let ac = ""
                if (user.email) {
                    // if (user.email === "a@b.com")
                    //     ac = "admin"
                    // else {
                    //     const idTokenResult = await user.getIdTokenResult()
                    //     if (idTokenResult.claims.facilitator) ac = "facilitator"
                    //     else ac = "user"
                    // }
                    const idTokenResult = await user.getIdTokenResult()
                    if (idTokenResult.claims.admin) ac = "admin"
                    else if (idTokenResult.claims.facilitator) ac = "facilitator"
                    else ac = "user"
                }
                batch(() => {
                    setAccess(ac)
                    setUser(user)
                })
                LoaderUtils.unhalt()
            } else {
                LoaderUtils.unhalt()
                // SnackbarUtils.info("Please Login to continue.")
                setUser(null)
                setAccess("user")
            }
        })
    }, [])
    // useEffect(() => {
    //     if (access && access !== "user") SnackbarUtils.info("Welcome " + access + "!")
    // }, [access])

    const authSyncSettings = {
        auth: auth,
        user: user,
        access: access,
        setUser: setUser,
        setAccess: setAccess,
        title, setTitle
    }

    return (
        <AuthContext.Provider value={authSyncSettings}>
            <SnackbarProvider
                dense
                preventDuplicate
                maxSnack={3}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                ref={notistackRef}
                action={key => (
                    <IconButton aria-label="Close" onClick={() => notistackRef.current.closeSnackbar(key)}>
                        <span className="material-icons" style={{ color: theme.palette.white.main }}>
                            close
                        </span>
                    </IconButton>
                )}
            >
                <div className="App"
                    style={{
                        backgroundImage: `url(${logo})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "50%",
                        height: "100vh",
                        height: "100dvh",
                    }}
                >
                    <Snack></Snack>
                    <Loader></Loader>
                    <NavBar state={true} />
                    {/* { emailVerify.ready && emailVerify.verified ? <VerifyEmail user={user} /> : null } */}
                    <Routes>
                        <Route exact path="/" element={<Join />} />
                        <Route exact path="/*" element={<Join />} />
                        <Route exact path="/login" element={<Home />} />
                        <Route exact path="/admin" element={<Admin />} />
                        <Route exact path="/facilitator" element={<Facilitator />} />
                        <Route exact path="/report/game/*" element={<GameReport />} />
                        <Route exact path="/report/session/*" element={<SessionReport />} />
                    </Routes>
                </div>
            </SnackbarProvider>
        </AuthContext.Provider>
    )
}

export default App
