import { Button, Dialog, DialogTitle, Stack, TextField } from "@mui/material"
import Autocomplete from "@mui/material/Autocomplete"
import Paper from "@mui/material/Paper"
import { getIdToken } from "firebase/auth"
import { collection, getDocs, query } from "firebase/firestore"
import React, { useContext, useEffect, useState } from "react"
import AuthContext from "../../firebase/auth/AuthContext"
import firestore from "../../firebase/firestore"
import bring from "../bring"
import LoaderUtils from "../Loader/LoaderUtils"
import SnackbarUtils from "../SnackbarUtils"
import StyledButton from "../StyledButton"

const LicenceEditor = props => {
    const [close, setClose] = useState(true)
    const authContext = useContext(AuthContext)
    const [values, setValues] = useState({
            licenceName : "",
            facilitatorName: undefined,
            facilitatorEmail: "",
            amount : 0,
            licenceId: null,
            notes : "",
    })
    const [facilitators, setFacilitators] = useState([])

    useEffect(() => {
        setFacilitators(props.facilitatorLists)
        getDocs(query(collection(firestore, "facilitators"))).then((doc) => {
            let docs = []
            doc.forEach((doca) => {
                docs.push(doca.data())
            })
            setFacilitators(docs)
        })
    }, [])
    useEffect(() => {
        try {
            let lname = values.facilitatorName.substring(0, 3).toUpperCase() + "XXXXXX"
            setValues({...values, licenceName: lname, licenceId: Date.now()})
        }
        catch (e) {
            //console.warn(e)
        }
    }, [values.facilitatorEmail])

    const uploadLicence = async () => {

        if (!values.licenceName) {
            SnackbarUtils.warning("Please enter a name.")
            return
        }
        if (!values.facilitatorEmail) {
            SnackbarUtils.warning("Please Choose a Facilitator.")
            return
        }
        if (!values.amount) {
            SnackbarUtils.warning("Please enter an amount.")
            return
        }
        if (parseInt(values.amount) > 1000) {
            SnackbarUtils.warning("Amount cannot be more than 1000.")
            return
        }
        LoaderUtils.open()
        // //console.log(authContext)
        try {
            // Remove true for faster response
            const jwt = await getIdToken(authContext.user, true)
            const data = {
                ...values,
                licenceName: values.facilitatorName.substring(0, 3).toUpperCase() + values.amount + (Date.now() % 100000),
                token: jwt,
            }
            const response = await bring({
                path: "/createLicence",
                options: {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                },
            })

            LoaderUtils.close()
            if (response.status === "success") {
                // props.setFacilitatorLists(list => list.push({
                //     licenceName: response.data.licenceName
                // }))
                SnackbarUtils.success("Created !")
                props.updateState(state => state + 1)
                setTimeout(() => {
                    props.setOpenEditor({ open: false, tab: null, details: null })
                }, 300)
            } else {
                //console.error(response)
                SnackbarUtils.error("Error : " + response.message)
            }
        } catch (error) {
            SnackbarUtils.error(error.message)
        } finally {
            LoaderUtils.close()
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={close}
            onClose={() => {
                SnackbarUtils.warning("Changes Discarded !")
                setClose(false)
                setTimeout(() => {
                    props.setOpenEditor({ open: false, details: null })
                }, 300)
            }}
        >
            <Paper elevation={3} sx={{ padding: 4, minWidth: 520 }}>
                <DialogTitle>
                    <span className="material-icons-outlined" style={{ verticalAlign: "sub", marginLeft: -24 }}>
                        post_add
                    </span>{" "}
                    Licence Editor
                    <span style={{ float: "right", fontSize: "small", margin: 8 }}>{props.details.details ? props.details.details.id : ""}</span>
                </DialogTitle>
                <Stack spacing={2}>
                    <Autocomplete
                        // multiple
                        // open={autoCompleteOpen}
                        // onOpen={loadList}
                        // onClose={() => setAutoCompleteOpen(false)}
                        value={values.facilitatorName}
                        options={facilitators}
                        // freeSolo
                        getOptionLabel={option => option.name}
                        onChange={(event, newInputValue) => {
                            if (newInputValue) setValues(values => ({ ...values, facilitatorName: newInputValue.name, facilitatorEmail: newInputValue.email }))
                            else setValues(values => ({ ...values, facilitatorName: null, facilitatorEmail: null }))
                        }}
                        onInputChange={(event, newInputValue) => {
                            //console.log(newInputValue)
                        }}
                        // renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)}
                        renderInput={params => <TextField {...params} variant="outlined" label="Search Facilitators" placeholder="Facilitator name" />}
                    />
                    <TextField label="Facilitator Email" disabled type="email" value={values.facilitatorEmail} placeholder="autofilled" variant="outlined" />
                    <TextField label="Licence Name" disabled value={values.licenceName} onChange={(event, value) => setValues({ ...values, licenceName: event.target.value })} variant="outlined" />
                    <TextField
                        label="Facilitator #Licences "
                        value={values.amount}
                        onChange={(event, value) => {
                            if (parseInt(event.target.value) > 999) {
                                SnackbarUtils.warning("Amount cannot be more than 1000.")
                                return
                            }
                            setValues({ ...values, amount: event.target.value })
                        }}
                        type="number"
                        variant="outlined"
                    />
                    {/* <TextField
                        label="Company"
                        value={values.notes}
                        onChange={(event, value) => {
                            setValues({ ...values, notes: event.target.value })
                        }}
                        variant="outlined"
                    /> */}
                </Stack>
                <br></br>
                <br></br>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                            SnackbarUtils.warning("Changes Discarded !")
                            setClose(false)
                            setTimeout(() => {
                                props.setOpenEditor({ open: false, tab: null, details: null })
                            }, 300)
                        }}
                        sx={{ marginRight: 2 }}
                    >
                        Discard
                    </Button>
                    <StyledButton
                        variant="contained"
                        color="button"
                        onClick={() => {
                            uploadLicence()
                        }}
                    >
                        Create
                    </StyledButton>
                </div>
            </Paper>
        </Dialog>
    )
}

export default LicenceEditor