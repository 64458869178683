import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import LoginWindow from "../components/MiniLogin/LoginWindow"
import AuthContext from "../firebase/auth/AuthContext"

function Home() {
    const matches = useMediaQuery("(min-width:756px)")
    const theme = useTheme()
    const authContext = React.useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        navigate(authContext.user ? "/" + authContext.access : "/login")
    }, [authContext.user])

    return (
        <div>
            <br></br>
            <br></br>
            {authContext.user ? null : <LoginWindow />}
        </div>
    )
}

export default Home
