import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyDXgBDV5bDEjOL49f_hq8JIrM_bo8XQsbs",
    authDomain: "goldmine-b4b44.firebaseapp.com",
    databaseURL: "https://goldmine-b4b44-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "goldmine-b4b44",
    storageBucket: "goldmine-b4b44.appspot.com",
    messagingSenderId: "327200842603",
    appId: "1:327200842603:web:0a7ebd68964b5497d3622e",
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)

export { firebaseApp }
