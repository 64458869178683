import { getDatabase, onValue, ref, runTransaction, set } from "firebase/database"
import { firebaseApp } from "../index"

class Database {
    constructor() {
        this.db = getDatabase(firebaseApp)
    }

    set = (path, value) => {
        return set(ref(this.db, path), value)
    }

    get = (path) => {
        return ref(this.db, path)
    }

    transaction = (path, callback) => {
        runTransaction(ref(this.db, path), callback)
    }

    getRealtime (path, callback) {
        onValue(ref(this.db, path), (snapshot) => {
            callback(snapshot.val())
        })
    }

    // depricated
    verifyFacilitator = async (email) => {
        return new Promise((resolve, reject) => {
            this.transaction("admin/facilitators/", (facilitators) => {
                if (facilitators && facilitators[email]) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            })
        })
    }
}

export default new Database()

/**
 * Database Structure
 * 
 * admin
 *     No of Facilitators <Number>
 *     No of Licences <Number>
 *     Licences
 *        LicenceID <String>
 *           Amount <Number>
 *           Facilitator Email <String>
 *           Facilitator uid <String>    (Assigned when available)
 *           Remaining <Number>
 *           Created <Date>
 * 
 * players
 *    uid
 *      name <String>
 *      games
 *         gameID <String> (New, Completed, Ongoing)
 * 
 * facilitators
 *    uid
 *      name <String>
 *      email <String>
 *      phone <String>
 *      notes <String>
 *      licences <Object>
 *         licenceID <Object>
 *             amount <Number>
 *             remaining <Number>
 *             name: licenceName,
 *             notes <String>
 *             created <Timestamp>
 *             facilitatorEmail <Email>
 *             games <Array><Object>
 *              gameID <Boolean>
 * 
 * games
 *   gameID
 *    Status <String> (New, Completed, Ongoing, Halted)
 *    Game Name <String>
 *    Session Name <String>
 *    Facilitator Name <String>
 *    Facilitator uid <String>
 *    Licence ID <String>
 *    GameData <Object>
 * 
 * 
 */