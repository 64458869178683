/**
 * @fileoverview
 * @author     {amankrokx}
 * 
 * @requires   {teams game data}
 * 
 * @param      {object}  Data     The key
 * @return     {object}  { Report }
 * 
 * @description
 * This will generate report for various activities from the player's game data of same team
 * 
 * ID				Events	 							Can Change
 *		
 * Purchase			Commulative Purchase event			Food / Water / Shekels / Lightning Shield / Hot Air Balloon
 * Purchase			Manuscript Purchase Event			( Depends upon Manuscript )
 * Move				Move / Stay events					Food / Water / Gold / Region
 * Use					Entity Use Events
 *  Balloon				Hot ait balloon					Hot Air Ballloon
 *	Siren				Siren use						Food / Water
 *	Lightning			Lightning Shield use			Lightning Shield / Gold
 *				
 * Key				Key activation (at R20)			    key
 * Earn				Manuscript / Gold Earn Event		Gold / Manuscript
 * UIEvent			Button Clicks						( Depends upon Button )
 * 
 */

 const GOLD_TO_SHEKELS = 10000

export default function sessionReportCalculator(sampleSet) {
    // List gold by teams, average gold, total gold
    // Total shekels, average shekels per day per team, total possible shekels
    // completed or surrended game , no of days
    // number of manuscripts earned
    let reportData = {
        totalGold: 15,
        totalWealth: 650 + 15 * GOLD_TO_SHEKELS,
        teams: [
            // {
            //     name: "aman023601",
            //     gold: 3,
            //     avgGoldOffset: 4,
            //     totalGoldOffset: 15 - 3,
            //     shekels: 600,
            //     wealth: 3 * GOLD_TO_SHELEKS + 600,
            //     wealthOffset: 0,
            //     days: 16,
            //     status: false,
            //     manuscript: 3,
            //     productivity: (3 * GOLD_TO_SHEKELS + 600) / 16,
            // },
        ],
        averageGold: 0,
    }

    let count = 0
    // console.log(sampleSet)
    Object.keys(sampleSet).forEach(key => {
        if (!sampleSet.hasOwnProperty(key)) return
        
        const game = sampleSet[key]
        const gameData = game.gameData.live
        if (sampleSet[key].status !== 2) gameData.gold = 0
        const gd = {
            gold: gameData.gold || 0,
            shekels: gameData.shekels || 0,
            day: gameData.day || 1,
            manuscript: gameData.manuscript || {},
        }

        reportData.averageGold += gd.gold
        count++
        // if (game.gameData.status > 1)
            reportData.teams.push({
                // name: key,
                name: game.gameName,
                gold: gd.gold || 0,            
                shekels: gd.shekels || 0,
                wealth: gd.gold * GOLD_TO_SHEKELS + gd.shekels,
                days: gd.day,
                manuscript: Object.keys(gd.manuscript).filter((key) => gameData.manuscript[key]).length,
                status: game.gameData.status === 2 ? true : false,
                productivity: (gd.gold * GOLD_TO_SHEKELS + gd.shekels) / gd.day,
            })
    })
    
    reportData.averageGold = reportData.averageGold / count

    for (let i = 0; i < reportData.teams.length; i++) {
        reportData.teams[i].avgGoldOffset = reportData.averageGold - reportData.teams[i].gold
        reportData.teams[i].totalGoldOffset = reportData.totalGold - reportData.teams[i].gold
        reportData.teams[i].wealthOffset = reportData.totalWealth - reportData.teams[i].wealth
        if (reportData.teams[i].avgGoldOffset < 0) reportData.teams[i].avgGoldOffset = 0
        if (reportData.teams[i].totalGoldOffset < 0) reportData.teams[i].totalGoldOffset = 0
        if (reportData.teams[i].wealthOffset < 0) reportData.teams[i].wealthOffset = 0
    }

    return reportData
}