import { Button, Dialog, DialogTitle, Stack, TextField } from "@mui/material"
import Paper from "@mui/material/Paper"
import { getIdToken } from "firebase/auth"
import React, { useContext, useState } from "react"
import AuthContext from "../../firebase/auth/AuthContext"
import bring from "../bring"
import LoaderUtils from "../Loader/LoaderUtils"
import SnackbarUtils from "../SnackbarUtils"
import StyledButton from "../StyledButton"


const FacilitatorEditor = props => {
    const [close, setClose] = useState(true)
    const authContext = useContext(AuthContext)
    const [values, setValues] = useState(
        props.details.details || {
            name : "",
            email : "",
            phone : "",
            notes : "Enter Company Name",
    })

    const uploadFacilitator = async () => {
        // SnackbarUtils.info("Not yet implemented.")
        //console.log(!/^\+[1-9]\d{10,14}$/.test(values.phone))
        //console.log(values.phone)
        if (!values.name) {
            SnackbarUtils.warning("Please enter a name.")
            return
        }
        if (!values.email || ! /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(values.email) ) {
            SnackbarUtils.warning("Please enter a valid email.")
            return
        }

        // if (!values.phone || ! /^[0-9]{10,13}$/.test(values.phone)) {
        //     SnackbarUtils.warning("Please enter a valid phone number.")
        //     return
        // }
        LoaderUtils.open()
        // //console.log(authContext)
        try {
            const jwt = await getIdToken(authContext.user, true)
            const data = {
                ...values,
                token: jwt,
            }
            const response = await bring({
                path : "/createFacilitator",
                options : {
                    method : "POST",
                    headers : {
                        "Content-Type" : "application/json"
                    },
                    body : JSON.stringify(data)
                }
            })
            
            LoaderUtils.close()
            if (response.status === "success") {
                SnackbarUtils.success(props.details.details && props.details.details.id ? "Updated !" : "Created !")
                props.updateState(state => state + 1)
                setTimeout(() => {
                    props.setOpenEditor({ open: false, tab: null, details: null })
                }, 300)
            } else {
                //console.error(response)
                SnackbarUtils.error("Error : " + response.message)
            }
            
        } catch (error) {
            SnackbarUtils.error(error.message)
        } finally {
            LoaderUtils.close()
        }
            
        
    }

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={close}
            onClose={() => {
                SnackbarUtils.warning("Changes Discarded !")
                setClose(false)
                setTimeout(() => {
                    props.setOpenEditor({ open: false, details: null })
                }, 300)
            }}
        >
            <Paper elevation={3} sx={{ padding: 4, minWidth: 520 }}>
                <DialogTitle>
                    <span className="material-icons-outlined" style={{verticalAlign: "sub", marginLeft: -24}}>post_add</span> Facilitator Editor
                    <span style={{ float: "right", fontSize: "small", margin: 8 }}>{props.details.details ? props.details.details.id : ""}</span>
                </DialogTitle>
                <Stack spacing={2}>
                    <TextField label="Facilitator Name" value={values.name} onChange={(event, value) => setValues({ ...values, name: event.target.value })} variant="outlined" />
                    <TextField label="Facilitator Email" value={values.email} onChange={(event, value) => setValues({ ...values, email: event.target.value })} variant="outlined" />
                    <TextField label="Facilitator Phone " value={values.phone} onChange={(event, value) => setValues({ ...values, phone: event.target.value })} type="tel" variant="outlined" />
                    {/* <Alert severity="info">Use E.164 standard compliant Phone number style. Ex : <b>+1 1234567890</b></Alert> */}
                    <TextField
                        label="Company"
                        value={values.notes}
                        onChange={(event, value) => {
                            setValues({ ...values, notes: event.target.value })
                        }}
                        variant="outlined"
                    />
                </Stack>
                <br></br>
                <br></br>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                            SnackbarUtils.warning("Changes Discarded !")
                            setClose(false)
                            setTimeout(() => {
                                props.setOpenEditor({ open: false, tab: null, details: null })
                            }, 300)
                        }}
                        sx={{ marginRight: 2 }}
                    >
                        Discard
                    </Button>
                    <StyledButton
                        variant="contained"
                        color="button"
                        onClick={() => {
                            uploadFacilitator()
                        }}
                    >
                        {values && values.id ? "Modify" : "Send Invite"}
                    </StyledButton>
                </div>
            </Paper>
        </Dialog>
    )
}

export default FacilitatorEditor