import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import Container from "@mui/material/Container"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/system"
import { ref, remove, update } from "firebase/database"
import { collection, doc, getDoc, getDocs, query, runTransaction, updateDoc, where, writeBatch } from "firebase/firestore"
import React, { useEffect } from "react"
import LoaderUtils from "../components/Loader/LoaderUtils"
import SnackbarUtils from "../components/SnackbarUtils"
import StyledButton from "../components/StyledButton"
import database from "../firebase/database"
import firestore from "../firebase/firestore"
import "./Join.css"

// generate random string of length 12
function randomString() {
    let result = ""
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
}

export default function Join() {
    const theme = useTheme()
    const [game, setGame] = React.useState({
        name: "",
        code: "",
    })
    const [open, setOpen] = React.useState(false)
    const passcode = React.useRef(null)

    useEffect(() => {
        const urlele = window.location.hash.substring(1)
        //console.log(urlele)
        if (urlele.length > 0) {
            setGame({ ...game, code: urlele })
            document.getElementById("gamename").focus()
        } else document.getElementById("gameid").focus()
    }, [])

    function startGame (code) {
        // make an i frame in fouuscreen mode and set url to game.html#code
        // const iframe = document.createElement("iframe")
        // iframe.src = "/game.html#" + code
        // iframe.style.position = "fixed"
        // iframe.style.top = "0"
        // iframe.style.left = "0"
        // iframe.style.width = "100%"
        // iframe.style.height = "100%"
        // iframe.style.border = "none"
        // iframe.style.zIndex = "999999"
        // document.body.appendChild(iframe)
        // // fullscreen mode for iframe
        // if (iframe.requestFullscreen) {
        //     iframe.requestFullscreen()
        // } else if (iframe.mozRequestFullScreen) {
        //     iframe.mozRequestFullScreen()
        // } else if (iframe.webkitRequestFullscreen) {
        //     iframe.webkitRequestFullscreen()
        // } else if (iframe.msRequestFullscreen) {
        //     iframe.msRequestFullscreen()
        // }
        window.location.href = "/game.html#" + code
    }

    async function resetLocalID (gameRef) {
        const localID = randomString()
        localStorage.setItem("localID", localID)
        const p1 = updateDoc(gameRef, {
            localID: localID,
        })
        const p2 = update(ref(database.db, "games/" + game.code), {
            localID: localID,
        })
        await Promise.all([p1, p2])
        startGame (game.code)
        // window.location.href = "/game.html#" + game.code
        return
    }

    function joinGame(pass, code) {
        LoaderUtils.halt()
        if (!pass && !(game.name != "" && game.code != "")) {
            SnackbarUtils.error("Please enter a name and a game code.")
            return
        }
        else if (pass && !(code.length > 0)) {
            SnackbarUtils.error("Please enter a valid game code.")
            return
        }
        if (game.name.length > 10) {
            SnackbarUtils.error("Name should be less than 10 characters.")
            return
        }
        if (!pass) code =  game.code
        //console.trace("Joining game...")
        runTransaction(firestore, async transaction => {
            const gameRef = doc(firestore, "games", code)
            const gameDoc = await transaction.get(gameRef)
            if (!gameDoc.exists) {
                throw new Error("Game does not exist!")
            }
            const gameData = gameDoc.data()
            if (!gameData) {
                throw new Error("Invalid Game Code")
            }
            if (!!gameData.gameName) {
                SnackbarUtils.info("Game already started !")
                const localID = window.localStorage.getItem("localID")
                //console.log(localID, gameData.localID)
                if (gameData.localID && gameData.localID === localID) {
                    //console.log("Redirecting to game...")
                    startGame (code)
                    // window.location.href = "/game.html#" + code
                    return
                } else {
                    if (!gameData.localID) 
                        await resetLocalID(gameRef)
                    else {
                        if (pass)
                            await resetLocalID(gameRef)
                        else 
                            throw new Error("Use Reload to join this game !")
                    }
                }
            }

            // check if game is not more than 8 hours old
            const batch = writeBatch(firestore)
            if (new Date(gameData.created.seconds * 1000) < new Date(Date.now() - 8 * 60 * 60 * 1000)) {
                const tempGameIDList = (await getDoc(doc(firestore, "licences", gameData.licence))).data().gameIds

                for (let i = 0; i < tempGameIDList.length; i++) {
                    if (tempGameIDList[i].licenceName === gameDoc.id) {
                        tempGameIDList[i] = {
                            licenceName: gameDoc.id,
                            gameName: null,
                            session: null,
                            status: 0,
                        }
                        batch.update(doc(firestore, "licences", gameData.licence), {
                            gameIds: tempGameIDList,
                        })
                        break
                    }
                }
                const forDeleteRef = query(collection(firestore, "forDeletion"), where("gameID", "==", gameDoc.id))
                const forDeleteDoc = await getDocs(forDeleteRef)
                forDeleteDoc.forEach(doc => {
                    batch.delete(doc.ref)
                })
                batch.delete(doc(collection(firestore, "games"), gameDoc.id))
                await batch.commit()
                throw new Error("Game has expired !")
            }

            const gameNameRef = doc(firestore, "licences", gameData.licence)
            const gameNameDoc = await transaction.get(gameNameRef)
            if (!gameNameDoc.exists) {
                throw new Error("Game does not exist!")
            }
            let gameNameData = gameNameDoc.data()
            const i = gameNameData.gameIds.findIndex(e => e.licenceName === code)
            if (i > -1) {
                /* vendors contains the element we're looking for, at index "i" */
                gameNameData.gameIds[i].gameName = game.name
                gameNameData.gameIds[i].status = 1
            }
            const localID = randomString()
            localStorage.setItem("localID", localID)
            transaction.update(gameRef, {
                gameName: game.name,
                status: 1,
                localID,
            })
            transaction.update(gameNameRef, {
                gameIds: gameNameData.gameIds,
            })
            const forDeleteRef = query(collection(firestore, "forDeletion"), where("gameID", "==", gameDoc.id))
            const forDeleteDoc = await getDocs(forDeleteRef)
            forDeleteDoc.forEach(async doc => {
                batch.delete(doc.ref)
            })
            const p3 = remove(ref(database.db, "games/" + code))
            const p4 = batch.commit()
            await Promise.all([p3, p4])
        })
            .then(async () => {
                LoaderUtils.unhalt()
                //console.log("Game Name successfully sved!")
                SnackbarUtils.success("Game started !")
                startGame (code)
                // window.location.href = "/game.html#" + code
            })
            .catch(error => {
                LoaderUtils.unhalt()
                //console.log("Transaction failed: ", error)
                SnackbarUtils.error(error.message || error)
            })
    }

    function validateURL (url) {
        // check if passcode.current.value is a valid url
        // regex to check valid url
        const regex = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        )
        if (!regex.test(url)) {
            SnackbarUtils.error("Invalid URL")
            return
        }
        const hash = url.split("#")[1]
        if (hash.length <= 8) {
            SnackbarUtils.error("Invalid URL")
            return
        }

        joinGame(true, hash)
    }

    return (
        <div>
            <Container
                maxWidth="sm"
                sx={{
                    boxShadow: 3,
                    mt: 4,
                    p: 2,
                    backdropFilter: "blur(10px)",
                    borderRadius: 1,
                    boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.2), " + theme.shadows[7],
                    background:
                        "linear-gradient(\
                            60deg,\
                            rgba(128,128,128,0.05) 0%,\
                            rgba(128,128,128,0.05) 60%,\
                            rgba(255,255,255,0.4) 65%,\
                            rgba(255,255,255,0.4) 70%,\
                            rgba(128,128,128,0.05) 75%,\
                            rgba(128,128,128,0.01) 100%\
                        )",
                    // background: "linear-gradient(322deg, \
                    //                 #ba4aff, rgba(#ba4aff, 1) 70%),\
                    //             linear-gradient(178deg,\
                    //                 #008aff, rgba(#008aff, 1) 70%),\
                    //             linear-gradient(24deg,\
                    //                 #00ffc6, rgba(#00ffc6, 0) 35%);"
                }}
            >
                <Typography variant="h3" color={theme.palette.black.main} sx={{ fontWeight: 400 }}>
                    Welcome Aboard!
                </Typography>
                <Typography variant="body" sx={{ fontWeight: "bold" }} color={theme.palette.grey.A700}>
                    Enter your name and the game code to join a game.
                </Typography>
                <Stack spacing={2} sx={{ p: 1, mt: 1 }}>
                    <TextField id="gameid" label="Game ID" value={game.code} onChange={event => setGame({ ...game, code: event.target.value })} variant="filled" />
                    <TextField id="gamename" label="Team Name" value={game.name} onChange={event => setGame({ ...game, name: event.target.value })} variant="filled" inputProps={{ maxlength: 10 }} />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: 24,
                        }}
                    >
                        <StyledButton variant="contained" color="neutral" sx={{ mr: 2 }} onClick={() => {
                            startGame('')
                            // window.location.href = "./game.html"
                        }}>
                            Demo
                        </StyledButton>
                        <StyledButton variant="contained" color="secondary" onClick={() => setOpen(true)}>
                            Reload
                        </StyledButton>
                        <StyledButton variant="contained" color="button" disabled={!(game.name != "" && game.code != "" && game.player != "")} onClick={() => joinGame(false, "")}>
                            Start
                        </StyledButton>
                    </div>
                </Stack>
            </Container>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle sx={{ fontWeight: "bold" }}>Please Enter Your Game Link</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        inputRef={passcode}
                        margin="dense"
                        label="Enter Game Link"
                        type="text"
                        fullWidth
                        onKeyUp={e => {
                            if (e.key === "Enter") {
                                validateURL(passcode.current.value)
                                setOpen(false)
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions sx={{ justifyContent: "flex-end", mb: 2, mr: 2 }}>
                    <StyledButton variant="contained" color="button" onClick={() => validateURL(passcode.current.value)}>
                        Submit
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}
