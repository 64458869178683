import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import FacilitatorManager from "../components/FacilitatorManager"
import SnackbarUtils from "../components/SnackbarUtils"
import AuthContext from "../firebase/auth/AuthContext"

export default function Admin() {
    const authContext = useContext(AuthContext)
    const navigator = useNavigate()
    useEffect(() => {
        document.title = "Admin | King Solomon's Mines"
        if (authContext.access == null) return
        if (!authContext.user) {
            navigator("/login")
            SnackbarUtils.info("Please login to access this page")
        }
        else if (authContext.access === "facilitator") {
            SnackbarUtils.toast("Redirecting...")
            navigator("/facilitator")
        }
    }, [authContext.user, authContext.access])
    // //console.log(user)

    return <>
        <FacilitatorManager />
    </>
}
