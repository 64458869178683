import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme, color = "button", variant }) => {
    const palette = theme.palette[color];
    let textColor = palette.buttonText || theme.palette.white.main
    if (variant === "outlined")
        textColor = palette.buttonText || theme.palette[color].main
    return {
        color: textColor,
        textShadow: !(variant === "outlined") && "0em 0.1em 0em rgb(0 0 0 / 40%)",
        "&:hover": {
            color: palette.buttonHoverText || theme.palette.black.main,
            backgroundColor: palette.buttonHoverBackground || theme.palette[color].hover,
            textShadow: palette.buttonHoverBackground ? undefined : "none",
        },
    }
})

export default StyledButton