import { createTheme, ThemeProvider } from "@mui/material/styles"
import "material-icons/iconfont/material-icons.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

const root = ReactDOM.createRoot(document.getElementById("root"))
const theme = createTheme({
    palette: {
        // primary: {
        //     main: "#4caf50",
        //     light: "#80e27e",
        //     dark: "#087f23",
        //     contrastText: "#fff",
        // },
        primary: {
            main: "#f47920", //Another orange-ish color
            buttonText: "#ffffff",
            buttonHoverText: "#ffffff",
            buttonHoverBackground: "#00b3dc",
            // light: "#80e27e",
            // dark: "#087f23",
            contrastText: "#ffffff",
        },
        gold: {
            main: "#FFD700",
        },
        button: {
            main: "#EE7204",
            hover: "#E7F0FF",
        },
        // secondary: {
        //     main: "#2979ff", //Another orange-ish color
        //     light: "#75a7ff",
        //     dark: "#004ecb",
        //     // contrastText: "#888888"
        // },
        secondary: {
            main: "#00b3dc",
            buttonText: "#ffffff",
            buttonHoverText: "#ffffff",
            buttonHoverBackground: "#f47920",
            // light: "#75a7ff",
            // dark: "#004ecb",
            // contrastText: "#888888"
        },
        navbar: {
            main: "#E7F0FF",
        },
        neutral: {
            main: "#64748B",
            contrastText: "#fff",
        },
        white: {
            main: "#ffffff",
        },
        black: {
            main: "#323232",
        },
        contrastThreshold: 3,
        // mode: "dark",
    },
})

// document.body.style.backgroundColor = theme.palette.background.default

window.theme = theme


root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
