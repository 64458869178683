import { Alert, Autocomplete, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, TextField } from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useTheme } from "@mui/system"
import { equalTo, get, orderByChild, query, ref } from "firebase/database"
import React, { Suspense, useContext, useEffect, useState } from "react"
import LoaderUtils from "../components/Loader/LoaderUtils"
import SnackbarUtils from "../components/SnackbarUtils"
import StyledButton from "../components/StyledButton"
import camelCaseToTitleCase from "../components/camelCaseToTitleCase"
import sessionReportCalculator from "../components/sessionReportCalculator"
import AuthContext from "../firebase/auth/AuthContext"
import database from "../firebase/database"
import "./Report.css"
const Chart = React.lazy(() => {
    LoaderUtils.halt()
    return import("../components/Charts")
})

function indexToManuscriptName (index) {
    switch (index) {
        case 0:
            return "Mysterious Forest"
        case 1:
            return "Manuscript 0"
        case 2:
            return "Mine and Tribe"
        case 3:
            return "Manuscript 4"
        case 4:
            return "Manuscript 5"
        default:
            return "Secret Manuscript"
    }
}

const parameters = {
    action: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="action" align="center">
                Action
            </TableCell>
        ),
        body: action => (
            <TableCell align="center" key="a">
                {camelCaseToTitleCase(action)
                    .replace(/ Click \d+/g, "")
                    .split(", ")
                    .map((f, i) => (
                        <>
                            <span key={i}>{f}</span>
                            <br />
                        </>
                    ))}
            </TableCell>
        ),
    },
    created: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="created" align="center">
                {/* <div> */}
                <span>Time</span>
                {/* <span className="material-icons">access_time</span>
                </div> */}
            </TableCell>
        ),
        body: time => (
            <TableCell align="center" key="b">
                {time.seconds > 270000
                    ? new Date(time.seconds * 1000)
                          .toLocaleTimeString()
                          .split(" ")
                          .map((f, i) => (i === 0 ? f.slice(0, -3) : f))
                          .join(" ")
                    : time.seconds}
            </TableCell>
        ),
    },
    day: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="day" align="center">
                {/* <div> */}
                <span>Day</span>
                {/* <span className="material-icons">calendar_today</span>
                </div> */}
            </TableCell>
        ),
        body: day => (
            <TableCell align="center" key="c">
                {/* {(day || 0) + (["st", "nd", "rd"][((((day + 90) % 100) - 10) % 10) - 1] || "th")} */}
                {day || 0}
            </TableCell>
        ),
    },
    foodWater: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="foodWater" align="center">
                {/* <div> */}
                <span>Food & Water</span>
                {/* <span className="material-icons">restaurant</span>
                </div> */}
            </TableCell>
        ),
        body: foodWater => (
            <TableCell align="center" key="d">
                {foodWater}
            </TableCell>
        ),
    },
    fuel: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="fuel" align="center">
                {/* <div> */}
                <span>Fuel</span>
                {/* <span className="material-icons">local_gas_station</span>
                </div> */}
            </TableCell>
        ),
        body: fuel => (
            <TableCell align="center" key="e">
                {fuel}
            </TableCell>
        ),
    },
    shekels: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="shekels" align="center">
                {/* <div> */}
                <span>Shekels</span>
                {/* <span className="material-icons">attach_money</span>
                </div> */}
            </TableCell>
        ),
        body: shekels => (
            <TableCell align="center" key="f">
                {shekels}
            </TableCell>
        ),
    },
    // "gameFinished",
    region: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="region" align="center">
                {/* <div> */}
                <span>Region</span>
                {/* <span className="material-icons">location_on</span>
                </div> */}
            </TableCell>
        ),
        body: region => (
            <TableCell align="center" key="g">
                {"R" + region}
            </TableCell>
        ),
    }, // Prepend Uppercase R
    gold: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="gold" align="center">
                {/* <div> */}
                <span>Gold</span>
                {/* <span className="material-icons">emoji_events</span>
                </div> */}
            </TableCell>
        ),
        body: gold => (
            <TableCell align="center" key="h">
                {gold}
            </TableCell>
        ),
    },
    hotAirBalloon: {
        head: (
            <TableCell key="hotAirBalloon" sx={{ fontWeight: "bold" }} align="center">
                {/* <div> */}
                <span>Balloon</span>
                {/* <span className="material-icons">flight</span>
                </div> */}
            </TableCell>
        ),
        body: hotAirBalloon => (
            <TableCell align="center" key="i">
                {/* {"x " + hotAirBalloon} */}
                {hotAirBalloon}
            </TableCell>
        ),
    },
    changeFoodWater: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="changeFoodWater" align="center">
                {/* <div> */}
                <span>
                    Change<br></br>Food / Water
                </span>
                {/* <span className="material-icons">restaurant</span>
                </div> */}
            </TableCell>
        ),
        body: changeFoodWater => (
            <TableCell align="center" key="j">
                {changeFoodWater}
            </TableCell>
        ),
    },
    // key: {
    //     head: (
    //         <TableCell sx={{ fontWeight: "bold" }} key="key" align="center">
    //             {/* <div> */}
    //             <span>Key</span>
    //             {/* <span className="material-icons">vpn_key</span>
    //             </div> */}
    //         </TableCell>
    //     ),
    //     body: (key, { keyActivated }) => (
    //         <TableCell align="center" key="j">
    //             {key !== "not_received" ? <span className="material-icons">vpn_key</span> : <span className="material-icons">close</span>}
    //         </TableCell>
    //     ),
    // }, // use keyIcon
    // "keyActivated", // use colored keyIcon
    // "keyIcon",
    lightningShield: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="lightningShield" align="center">
                {/* <div> */}
                <span>Shield</span>
                {/* <span className="material-icons">bolt</span>
                </div> */}
            </TableCell>
        ),
        body: shield => (
            <TableCell align="center" key="k">
                {/* {"x " + shield} */}
                {shield}
            </TableCell>
        ),
    },
    manuscript: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="manuscript" align="center">
                {/* <div> */}
                <span>Manuscript</span>
                {/* <span className="material-icons">book</span>
                </div> */}
            </TableCell>
        ),
        body: manuscript => (
            <TableCell align="center" key="l">
                {/* object of 4 boolean */}
                {/* {Object.values(manuscript).filter(b => b).length} */}
                {Object.values(manuscript).map((b, index) =>
                    b ? (
                        <>
                            <span>{indexToManuscriptName(index)}</span>
                            <br></br>
                        </>
                    ) : null
                )}
            </TableCell>
        ),
    }, // count
    sirenUsed: {
        head: (
            <TableCell sx={{ fontWeight: "bold" }} key="sirenUsed" align="center">
                {/* <div> */}
                <span>Siren</span>
                {/* <span className="material-icons">campaign</span>
                </div> */}
            </TableCell>
        ),
        body: siren => (
            <TableCell align="center" key="m">
                {siren ? "Yes" : "-"}
            </TableCell>
        ),
    }, // Bool
    // "started",
    // "weatherReportIcon"
}

const editable = [
    // "action",
    // "created",
    // "day",
    "foodWater",
    "fuel",
    // "gameFinished", // is it ?
    // "gold",
    "hotAirBalloon",
    // "key",
    // "keyActivated",
    // "keyIcon",
    "lightningShield",
    // "manuscript",
    // "region",
    // "shekels",
    // "sirenUsed",
    // "started",
    // "weatherReportIcon",
]

function GameReport() {
    const theme = useTheme()
    const [editor, setEditor] = useState(false)
    const [manuscriptsHistory, setManuscriptsHistory] = useState({
        "1": false,
        "2": false,
        "3": false,
        "4": false,
        "5": false,
    })
    const [live, setLive] = useState({})
    const [history, setHistory] = useState([])

    const updateValues = () => {
        database.set(`games/${window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)}/gameData/live`, live).then(() => {
            SnackbarUtils.success("Updated live data.")
            setEditor(false)
        }).catch((e) => {
            SnackbarUtils.error(e.toString())
        })
    }

    useEffect(() => {
        const gameID = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1)
        document.title = "Game Report | King Solomon's Mines"
        database.getRealtime(`games/${gameID}/gameData/live`, setLive)
        database.getRealtime(`games/${gameID}/gameData/history`, data => {
            // console.log(data)
            const manuscriptShowFlags = {
                "1": false,
                "2": false,
                "3": false,
                "4": false,
                "5": false
            }
            // group by day, discard the rest and leave only last entry of each day, club the actions attribute as concatenated string
            // remove duplicates, if day, action, foodWater, fuel, gold and length of manuscript are same then remove it
            setHistory(Object
                .values(data)
                .slice(1)
                .reduce((acc, curr, index, arr) => {
                    if (index === 0) {
                        acc.push(curr)
                        return acc
                    }
                    const prev = acc.pop()
                    if (prev.day === curr.day) {
                        // club the actions
                        // split by , and \n
                        const oldActions = prev.action.split("\n").flatMap(action => action.split(", ")) // flatMap to flatten the array
                        const actions = new Set([...oldActions, curr.action])
                        // make string seperated by \n
                        // console.log(actions)
                        const action = [...actions].join(", ")
                        return [...acc, { ...curr, action }]
                    } else return [...acc, prev, curr]
                }, [])
                .map((data, index, arr) => {
                    // time in seconds is in created.seconds, subtract from first element's value and return the relative time
                    const diff = data.created.seconds - arr[index - 1]?.created?.seconds || 0
                    // const time = moment.duration(diff, "seconds").humanize()
                    // only show manuscript if it is not already shown, use the manuscriptShowFlags object
                    if (data.manuscript) {
                        const manuscript = data.manuscript
                        // manuscript["1"] = manuscriptShowFlags["1"] ? false : manuscript["1"]
                        Object.keys(manuscript).forEach(key => {
                            manuscript[key] = manuscriptShowFlags[key] ? false : manuscript[key]
                            manuscriptShowFlags[key] = manuscript[key] ? true : manuscriptShowFlags[key]
                        })
                        data.manuscript = manuscript
                    }
                    return { ...data, created: {seconds: diff, _seconds: data.created.seconds}}
                })
                .reverse()
            )
            setManuscriptsHistory(manuscriptShowFlags)
        })
    }, [])
    return (
        <div>
            <h1 style={{ paddingLeft: 40 }}>
                <IconButton variant="text" sx={{ mr: 2 }} color="primary" onClick={() => window.history.back()}>
                    <span style={{ color: theme.palette.primary.main }} className="material-icons">
                        arrow_back
                    </span>
                </IconButton>
                Game Report
                <IconButton variant="text" sx={{ ml: 2 }} color="primary" onClick={() => setEditor(true)}>
                    <span style={{ color: theme.palette.primary.main }} className="material-icons">
                        edit_note
                    </span>
                </IconButton>
            </h1>
            <Paper sx={{ width: "calc(100% - 80px)", margin: "0 auto", overflow: "hidden" }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow className="gameHead">
                                {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>
                                    Step
                                </TableCell>
                                {parameters["changeFoodWater"] && parameters["changeFoodWater"].head} */}

                                {parameters["day"] && parameters["day"].head}
                                {Object.keys(history[0] || []).map(key => {
                                    if (parameters[key] && key !== "day") return parameters[key].head
                                    else return null
                                })}
                            </TableRow>
                            <TableRow>
                                {/* <TableCell align="center">
                                    <IconButton variant="text" color="primary" onClick={() => setEditor(true)}>
                                        <span style={{ color: theme.palette.primary.main }} className="material-icons">
                                            edit_note
                                        </span>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    -
                                </TableCell> */}
                                {parameters["day"] && parameters["day"].body(live["day"], live)}
                                {Object.keys(history[0] || []).map(key => {
                                    if (!parameters[key]) return null
                                    switch (key) {
                                        case "day":
                                            return null
                                        case "created":
                                            const created = {
                                                seconds: live[key].seconds - history[0].created._seconds,
                                            }
                                            console.log(created.seconds)
                                            return parameters[key].body(created, live)
                                        case "manuscript":
                                            const currentManuscript = live[key]
                                            const manuscript = {}
                                            Object.keys(currentManuscript).forEach(key => {
                                                manuscript[key] = currentManuscript[key] && !manuscriptsHistory[key]
                                            })
                                            return parameters[key].body(manuscript, live)
                                        default:
                                            return parameters[key].body(live[key], live)
                                    }
                                })}
                            </TableRow>
                            {/* <TableRow>
                                <TableCell colSpan={12} align="center">
                                    <Typography variant="caption" color="primary">
                                        Previous Moves
                                    </Typography>
                                </TableCell>
                            </TableRow> */}
                        </TableHead>
                        <TableBody>
                            {history.map((row, index) => {
                                // // // delete action and created keys of current and next row, compare, if same , delete current one
                                // if (index < history.length - 1) {
                                //     const current = { ...row }
                                //     const next = { ...history[index + 1] }
                                //     delete current.action
                                //     delete current.created
                                //     delete next.action
                                //     delete next.created
                                //     delete current.keyIcon
                                //     delete next.keyIcon
                                //     console.log(JSON.stringify(current), JSON.stringify(next))
                                //     if (JSON.stringify(current) === JSON.stringify(next)) return null
                                // }

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {/* <TableCell align="center">{index + 1}</TableCell> */}
                                        {/* {parameters["changeFoodWater"] && parameters["changeFoodWater"].body(history[index - 1 > -1 ? index - 1 : 0].foodWater - row.foodWater)} */}
                                        {parameters["day"] && parameters["day"].body(row["day"], row)}
                                        {Object.keys(row).map(key => {
                                            if (parameters[key] && key !== "day") return parameters[key].body(row[key], row)
                                            else return null
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <Dialog fullWidth maxWidth="lg" open={editor} onClose={() => setEditor(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle>Add Resources</DialogTitle>
                <DialogContent>
                    <DialogContentText>Change below parameters to help players with game.</DialogContentText>
                    <br></br>
                    {Object.keys(live).map((key, index) =>
                        editable.includes(key) ? (
                            <TextField
                                required
                                type="number"
                                label={camelCaseToTitleCase(key)}
                                value={live[key] || 0}
                                onChange={e => setLive({ ...live, [key]: parseInt(e.target.value || 0) })}
                                sx={{ width: "calc(40% + 48px)", margin: 2 }}
                            />
                        ) : null
                    )}

                    <Alert severity="warning" sx={{ marginTop: 2 }}>
                        This action will directly affect gameplay!
                    </Alert>
                </DialogContent>
                <DialogActions sx={{ pr: 3, pb: 3 }}>
                    <StyledButton
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                            setEditor(false)
                        }}
                        sx={{ mr: 2 }}
                    >
                        Cancel
                    </StyledButton>
                    <StyledButton variant="contained" color="secondary" onClick={updateValues} autoFocus>
                        Assign
                    </StyledButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

function SessionReport() {
    const theme = useTheme()
    const [sessionData, setSessionData] = useState({})
    const [loaded, setLoaded] = useState([false, false])
    const [items, setItems] = useState([
        "Completed vs Surrendered",
        "Manuscripts",
        "Maximising Mindset",
        "Maximising Mindset - Performance vs Potential",
        // "Maximising Mindset Performance vs Average",
        "Wealth Created by Teams",
        "Productivity",
    ])
    const [selected, setSelected] = useState(items[0])
    const authContext = useContext(AuthContext)

    useEffect(() => {
        document.title = "Session Report | King Solomon's Mines"
        authContext.setTitle("The Quest for King Solomon's Mines")
        if (!authContext.user) return
        const sessionID = decodeURI(window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1))
        //console.log(sessionID)
        const sessionGamesRef = query(ref(database.db, 'games'), orderByChild('session') ,equalTo(sessionID))
        LoaderUtils.halt()
        // fetchinmg all games of same session
        get(sessionGamesRef).then(data => {
            let d = data.val() || {}

            Object.keys(d).forEach(key => {
                if (authContext.access !== "admin" &&  d[key].facilitator !== authContext.user.uid) 
                    delete d[key]
                else
                    delete d[key].gameData.history
            })
            setSessionData(sessionReportCalculator(d))
            setLoaded(s => [true, s[1]])
        })
    }, [authContext.user])
    useEffect(() => {
        if (loaded[0] && loaded[1])
            LoaderUtils.unhalt()
    }, [loaded])
    useEffect(() => {
        setLoaded(s => [s[0], true])
    }, [Chart])

    function color (color, d) {
        // //console.log(color, d)
        if (d.id === "Gold") {
            // if (d.value < 5) return theme.palette.error.main
            // if (d.value < 10) return theme.palette.warning.main
            // else return theme.palette.primary.main
            return theme.palette.gold.main
        }
        if (d.id === "Avg Gold Offset") return theme.palette.secondary.main
        if (d.id === "Total Gold Offset") return theme.palette.secondary.main
        if (d.id === "Productivity") {
            // if (d.value < 500) return theme.palette.error.main
            // if (d.value < 3000) return theme.palette.warning.main
            // else return theme.palette.primary.main
            return theme.palette.primary.main
        }
        if (d.id === "Wealth") {
            // if (d.value < 5216) return theme.palette.error.main
            // if (d.value < 10432) return theme.palette.warning.main
            // else return theme.palette.primary.main
            return theme.palette.primary.main
        }
        if (d.id === "Wealth Offset") return theme.palette.secondary.main
        if (d.id === "Days") {
            if (sessionData.teams[d.index].status) return theme.palette.secondary.main
            else return theme.palette.error.main
        }

        if (d.id === "Manuscript") return theme.palette.primary.main
    }

    function chartOptions (title, data) {
        const index = items.indexOf(title)
        const teams = sessionData.teams.map(team => {
            // team is an object
            // change Key name to Title Case from camelCase
            const newTeam = {}
            Object.keys(team).forEach(key => {
                newTeam[camelCaseToTitleCase(key)] = team[key]
            })
            return newTeam
        })
        //console.log( sessionData.teams, teams)

        switch (index) {
        case 2:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: ["Gold"],
                    },
                    type: "bar",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                        
                    },
                    y: {
                        max: 16,
                        min: 0,
                        label: {
                            text: "No. of Gold",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0,
                        },
                    },
                },
                grid: {
                    y: {
                        lines: [
                            { value: sessionData.averageGold, text: "Average Gold Earned" },
                            { value: sessionData.totalGold, text: "Total Gold" },
                        ],
                    },
                },
            }
        case 3:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: ["Gold", "Total Gold Offset"],
                    },
                    groups: [["Gold", "Total Gold Offset"]],
                    type: "bar",
                    order: "des",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                    },
                    y: {
                        max: sessionData.totalGold,
                        min: 0,
                        label: {
                            text: "Total No. of Gold",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0
                        },
                    },
                },
                grid: {
                    y: {
                        lines: [{ value: sessionData.totalGold, text: "Total earnable Gold" }],
                    },
                },
            }
        // case 2:
        //     return {
        //         data: {
        //             x: "x",
        //             json: teams,
        //             keys: {
        //                 x: "Name",
        //                 value: ["Avg Gold Offset", "Gold"],
        //             },
        //             groups: [["Avg Gold Offset", "Gold"]],
        //             type: "bar",
        //             order: "asc",
        //             color,
        //         },
        //         bar: {
        //             width: 80, // this makes bar width 100px
        //         },
        //         axis: {
        //             x: {
        //                 type: "category", // this needed to load string x value
        //             },
        //             y: {
        //                 max: sessionData.averageGold,
        //                 min: 0,
        //                 label: {
        //                     text: "No. of Golds",
        //                     position: "outer-middle",
        //                 },
        //                 padding: {
        //                     top: 0,
        //                     bottom: 0
        //                 },
        //             },
        //         },
        //         grid: {
        //             y: {
        //                 lines: [{ value: sessionData.averageGold, text: "Average Gold Earned" }],
        //             },
        //         },
        //     }
        case 5:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: ["Productivity"],
                    },
                    groups: [["Productivity"]],
                    type: "bar",
                    order: "asc",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                    },
                    y: {
                        // max: sessionData.averageGold,
                        min: 0,
                        label: {
                            text: "Productivity per Day",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0
                        },
                    },
                },
                grid: {
                    // y: {
                        //     lines: [
                            //         { value: sessionData.averageGold, text: "Average Gold Earned" },
                            //     ],
                            // },
                        },
            }
        case 4:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: [ "Wealth", "Wealth Offset"],
                    },
                    groups: [[ "Wealth", "Wealth Offset"]],
                    type: "bar",
                    // order: "des",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                    },
                    y: {
                        max: sessionData.totalWealth,
                        min: 0,
                        label: {
                            text: "Wealth Earned",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0
                        },
                    },
                },
                grid: {
                    y: {
                        lines: [{ value: sessionData.totalWealth, text: "Total Earnable Wealth" }],
                    },
                },
            }
        case 0:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: ["Days"],
                    },
                    groups: [["Days"]],
                    type: "bar",
                    order: "asc",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                    },
                    y: {
                        min: 0,
                        label: {
                            text: "No. of Days",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0
                        }
                    },
                },
                grid: {
                    y: {
                        lines: [{ value: sessionData.totalWealth, text: "Total Earnable Wealth" }],
                    },
                },
                customLegend: true
            }
        case 1:
            return {
                data: {
                    x: "x",
                    json: teams,
                    keys: {
                        x: "Name",
                        value: ["Manuscript"],
                    },
                    groups: [["Manuscript"]],
                    type: "bar",
                    // order: "asc",
                    color,
                },
                bar: {
                    width: 80, // this makes bar width 100px
                },
                axis: {
                    x: {
                        type: "category", // this needed to load string x value
                        padding: {
                            top: 0,
                            bottom: 0,
                        },
                    },
                    y: {
                        min: 0,
                        label: {
                            text: "Manuscripts Used",
                            position: "outer-middle",
                        },
                        padding: {
                            top: 0,
                            bottom: 0
                        },
                        max: 2,
                        tick: {
                            // values: [0, 1, 2, 3],
                            count: 3,
                        }
                    },
                },
                grid: {
                    y: {
                        lines: [{ value: sessionData.totalWealth, text: "Total Earnable Wealth" }],
                    },
                },
            }
        default:
        }
    }
                
    return (
        <div style={{
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            backdropFilter: "blur(3px)",
        }}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "nowrap",
                    justifyContent: "space-between",
                    margin: "16px 40px",
                }}
            >
                <div
                    style={{
                        lineHeight: "1",
                    }}
                >
                    <h2>
                        
                        <IconButton variant="text" sx={{ mr: 0.5 }} color="primary" onClick={() => window.history.back()}>
                            <span style={{ color: theme.palette.primary.main }} className="material-icons">
                                arrow_back
                            </span>
                        </IconButton>
                        Session Report :</h2>
                    <h1 style={{ paddingLeft: 8, textDecoration: "underline", textUnderlineOffset: 2 }}>{selected}</h1>
                </div>
                <div>
                    <Autocomplete
                        disablePortal
                        options={items}
                        value={selected}
                        size="small"
                        onChange={(event, newValue) => setSelected(newValue)}
                        sx={{
                            width: 300,
                            userSelect: "none",
                        }}
                        clearIcon={<span style={{ display: "none" }}></span>}
                        disableClearable={true}
                        openOnFocus={true}
                        renderInput={params => (
                            <TextField
                                sx={{
                                    userSelect: "none",
                                }}
                                {...params}
                                label="Graphs"
                            />
                        )}
                    />
                    {items.indexOf(selected) === 0 ?
                        <ul id="legend">
                            <li style={{
                                color: theme.palette.secondary.main,
                            }}><span>Completed</span></li>
                            <li style={{
                                color: theme.palette.error.main,
                            }}><span>Surrendered</span></li>
                        </ul>
                        :
                        null
                    }
                </div>
            </div>
            {sessionData && sessionData.teams ? (
                <Suspense fallback={<div>Loading...</div>}>
                    <Chart data={chartOptions(selected, sessionData)} />
                </Suspense>
            ) : (
                <div style={{ margin: "16px 40px" }}>
                    <Alert severity="warning">No data to show</Alert>
                </div>
            )}
            {/* <center>
                <Typography variant="h3" color="initial">
                    {selected}
                </Typography>
            </center> */}
        </div>
    )
}

export { GameReport, SessionReport }

