import { useTheme } from "@emotion/react"
import {
    Alert,
    Divider,
    Tab,
    Tabs,
    Tooltip,
    useMediaQuery
} from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import Slide from "@mui/material/Slide"
import algoliasearch from "algoliasearch/lite"
import { getIdToken } from "firebase/auth"
import React, { useContext, useState } from "react"
import { Hits, InstantSearch, SearchBox } from "react-instantsearch-hooks-web"
import AuthContext from "../../firebase/auth/AuthContext"
import bring from "../bring"
import FacilitatorEditor from "../FacilitatorManager/FacilitatorEditor"
import LoaderUtils from "../Loader/LoaderUtils"
import SnackbarUtils from "../SnackbarUtils"
import StyledButton from "../StyledButton"
import "./index.css"
const client = algoliasearch("1KGKVUOU69", "9ce11465c923f9300cca30804edfef16")


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

function Hit({ hit }) {
    const [openEditor, setOpenEditor] = useState({ open: false, details: null })
    const [dialog, setDialog] = useState({
        open: false,
        type: null,
        index: -1,
        id: null,
        email: null,
    })
    const [opacity, setOpacity] = useState(0)
    const theme = useTheme()
    const authContext = useContext(AuthContext)
    const deletelicence = async () => {
        LoaderUtils.halt()

        try {
            const jwt = await getIdToken(authContext.user, true)
            const data =
                dialog.type === "licence"
                    ? {
                          id: hit.objectID,
                          token: jwt,
                      }
                    : {
                          email: hit.email,
                          uid: hit.objectID,
                          token: jwt,
                      }
            const response = await bring({
                path: dialog.type === "licence" ? "/deleteLicence" : "/deleteFacilitator",
                options: {
                    method: "POST",
                    credentials: "include",
                    cors: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                },
            })

            LoaderUtils.unhalt()
            if (response.status === "success") {
                SnackbarUtils.success("Deletion Successful")
                setDialog({
                    open: false,
                    type: null,
                    index: -1,
                })
            } else {
                //console.error(response)
                SnackbarUtils.error("Error : " + response.message)
                setDialog({
                    open: false,
                    type: null,
                    index: -1,
                })
            }
        } catch (error) {
            setDialog({
                open: false,
                type: null,
                index: -1,
            })
            SnackbarUtils.error(error.message || error)
        } finally {
            setDialog({
                open: false,
                type: null,
                index: -1,
            })
            LoaderUtils.unhalt()
        }
    }

    if (!hit._highlightResult)
        return null

    return (
        <>
            {hit.amount ? (
                <article key={hit.__position}>
                    <div>
                        <div>
                            <div>Amount #</div>
                            <div>Facilitator</div>
                            <div>Licence</div>
                        </div>
                        <div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.amount.value }} />
                            </div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.facilitatorName.value }} />
                            </div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.licenceName.value }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Tooltip title="Delete Licence" arrow>
                            <Button
                                variant="text"
                                color="error"
                                onClick={() => {
                                    setDialog({ open: true, type: "licence" })
                                }}
                            >
                                <span className="material-icons">delete</span>
                            </Button>
                        </Tooltip>
                    </div>
                </article>
            ) : (
                <article key={hit.__position} onMouseEnter={() => setOpacity(1)} onMouseLeave={() => setOpacity(0)}>
                    <div>
                        <div>
                            <div>Email</div>
                            <div>Name</div>
                            <div>Phone</div>
                            <div>Notes</div>
                        </div>
                        <div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.email.value }} />
                            </div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.name.value }} />
                            </div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.phone.value }} />
                            </div>
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: hit._highlightResult.notes.value }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Tooltip title="View / Edit facilitator" arrow>
                            <Button
                                variant="text"
                                color="secondary"
                                onClick={() => {
                                    setOpenEditor({
                                        open: true,
                                        details: {
                                            name: hit.name,
                                            email: hit.email,
                                            phone: hit.phone,
                                            notes: hit.notes,
                                            id: hit.objectID,
                                        },
                                    })
                                }}
                                sx={{
                                    position: "relative",
                                    opacity: opacity,
                                    transition: "opacity 0.2s ease-in-out",
                                }}
                            >
                                <span className="material-icons">edit</span>
                            </Button>
                        </Tooltip>
                        <Tooltip title="Delete Licence" arrow>
                            <Button
                                variant="text"
                                color="error"
                                onClick={() => {
                                    setDialog({ open: true, type: "facilitator" })
                                }}
                            >
                                <span className="material-icons">delete</span>
                            </Button>
                        </Tooltip>
                    </div>
                </article>
            )}
            {dialog.open && (
                <Dialog open={dialog.open} onClose={() => setDialog({ open: false, index: -1 })} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>{"Confirm Delete ?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure to proceed with deleting <b>{hit.name || hit.facilitatorName}</b>
                            <br></br>
                            <Alert severity="error">This action cannot be undone.</Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <StyledButton
                            variant="outlined"
                            onClick={() => {
                                setDialog({ open: false, index: -1 })
                            }}
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton variant="contained" color="error" onClick={deletelicence} autoFocus>
                            Delete
                        </StyledButton>
                    </DialogActions>
                </Dialog>
            )}
            {openEditor.open ? <FacilitatorEditor details={openEditor} setOpenEditor={setOpenEditor} /> : null}
        </>
    )
  
}

export default function SearchSpace({ searchDialog, setSearchDialog}) {
    const theme = useTheme()
    const matches = useMediaQuery("(min-width:756px)")
    

    return (
        <>
            <Dialog
                open={true}
                maxWidth="xl"
                fullWidth={true}
                TransitionComponent={Transition}
                keepMounted
                className="search-dialog"
                onClose={() => setSearchDialog({ ...searchDialog, open: false })}
                aria-describedby="alert-dialog-slide-description"
            >
                <InstantSearch
                    searchClient={client}
                    indexName={searchDialog.type === "facilitator" ? "facilitators" : "licences"}
                >
                    <div
                        style={{
                            position: "relative",
                            backgroundColor: theme.palette.white.main,
                            display: "flex",
                            justifyContent: "space-between",
                            minWidth: 300,
                            // margin: "0 auto",
                            width: "100%",
                            backgroundColor: theme.palette.navbar.main,
                        }}
                    >
                        <div style={{ display: "flex", minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: 0, marginRight: "auto", width: "100%" }}>
                            <Tabs
                                // style={{ minWidth: 300, maxWidth: matches ? "calc(100% - 80px)" : "100%", margin: "0 auto", width: "100%" }}
                                value={searchDialog.type}
                                onChange={(event, newValue) => setSearchDialog({ open: true, type: newValue })}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="licences and facilitators Tabs"
                            >
                                <Tab value="licence" label="Search Licences" />
                                <Tab value="facilitator" label="Search Facilitators" />
                            </Tabs>
                        </div>
                        <SearchBox
                            style={{
                                marginRight: 8,
                                // marginLeft: "auto",
                            }}
                            searchAsYouType
                            autoFocus
                            classNames={{
                                root: "search-box-root",
                                input: "search-input",
                                submit: "search-submit",
                                form: "search-form",
                                reset: "search-reset-icon",
                                loadingIndicator: "search-loading-indicator",
                            }}
                            placeholder={searchDialog.type === "facilitator" ? "Search Facilitators" : "Search Licences"}
                        />
                    </div>
                    {/* <SortBy
                        items={[
                            { label: "Featured", value: "instant_search" },
                            { label: "Price (asc)", value: "instant_search_price_asc" },
                            { label: "Price (desc)", value: "instant_search_price_desc" },
                        ]}
                    /> */}
                    <Hits style={{ borderTop: "2px solid #eaeaea" }} hitComponent={Hit} />
                    <Divider />
                    <div style={{ display: "flex", justifyContent: "center", padding: 16 }}>End of results</div>
                </InstantSearch>
            </Dialog>
        </>
    )
}
